.request
  position: relative
  width: 100%
  font: normal 300 1.2rem/1.3rem var(--main-font)
  color: #575F6E
  @media (min-width: $middle-min)
    margin: 0 auto 20px
  @media (max-width: $middle-max)
    margin: 60px auto

  &__title
    font: normal 600 3.2rem/112% var(--main-font)
    text-align: center
    color: #36487A

  &__body
    max-width: 491px
    margin: 16px auto 0
    font: normal 300 1.6rem/2.2rem var(--main-font)
    text-align: center
    color: #575F6E

  &__form
    max-width: 491px
    margin: 51px auto 0
    background-color: #F8FAFD
    border: 1px solid rgba(0, 53, 156, 0.3)
    box-shadow: 0px 4px 8px rgba(57, 57, 57, 0.09)
    border-radius: 9px
    @media (min-width: 376px)
      padding: 32px 27px
    @media (max-width: 375px)
      padding: 20px 10px

  &__success
    position: fixed
    top: -100px
    left: 0
    width: 100%
    padding: 20px
    background-color: #28a745
    color: var(--color-white)
    font: 400 1.6rem var(--main-font)
    transition: all .3s ease-in-out

.form
  &__title
    margin-bottom: 44px
    font: 500 2.1rem/133% var(--main-font)
    color: #363636

  &__input
    width: 100%
    margin-bottom: 21px
    padding: 19px 14px
    border: 1px solid rgba(0, 53, 156, 0.3)
    border-radius: 7px
    font: 500 1.6rem/2rem var(--main-font)
    color: #363636
    &::placeholder
      color: #8F8F8F
    &:focus
      border-color: var(--color-second)
      box-shadow: 0 0 0 1px var(--color-second)
    &:filled
      border-color: tomato
    &--textarea
      height: 200px
    &.invalid
      border-color: tomato

  &__group
    display: flex

  &__select
    position: relative
    display: inline-block
    margin-bottom: 22px
    border: 1px solid rgba(0, 53, 156, 0.3)
    border-radius: 7px
    &::after
      content: ""
      position: absolute
      top: 0
      bottom: 0
      right: 14px
      margin: auto 0
      width: 20px
      height: 20px
      pointer-events: none
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%2300359C' stroke-linecap='round' stroke-opacity='.5' stroke-width='2' d='m5 8 3.82 3.55c.65.6 1.7.6 2.36 0L15 8'/%3E%3C/svg%3E") no-repeat
    @media (min-width: $small-min)
      width: 108px
      &--month
        width: 140px
      &:not(:last-of-type)
        margin-right: 24px
    @media (max-width: $small-max)
      width: 100%
      &--month
        min-width: 110px
      &:not(:last-of-type)
        margin-right: 5px
      &::after
        display: none
    select
      width: 100%
      height: 100%
      padding: 18px 14px
      border-radius: 7px
      font: 400 1.6rem/2rem var(--main-font)
      cursor: pointer
      &:focus
        box-shadow: 0 0 0 2px var(--color-second)

  &__button
    width: 100%
    padding: 14px
    background: var(--color-second)
    font: 500 1.9rem/150% var(--main-font)
    border-radius: 5px
    color: #FFFFFF
    transition: all .2s
    cursor: pointer
    &:hover
      background: #334a8b
    &:focus-visible
      background: #334a8b
