.preload
  position: relative
  height: 100vh
  background-image: url('../images/bg-preload.jpg')
  background-repeat: no-repeat
  background-size: cover
  @media (min-width: $middle-min)
    padding: 180px 0
    background-position: right
  @media (max-width: $middle-max)
    padding: 120px 0
    background-position: 75%
  &__inner
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100%

  &__contacts
    margin-top: 30px

  &__item
    color: #363636
    @media (max-width: $small-max)
      &:not(:last-of-type)
        margin-bottom: 15px
      span
        display: block
        text-align: center
    span
      font: 500 2.4rem/125% var(--main-font)

  &__link, &__address
    margin-left: 5px
    font: 400 2.4rem/125% var(--main-font)
    color: inherit
    @media (min-width: $small-min)
      display: inline-block
    @media (max-width: $small-max)
      display: block
      text-align: center

  &__link
    transition: all .2s
    &:hover
      color: var(--color-black)
      text-decoration: underline
