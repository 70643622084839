//---- Base
@import "__reset", "__variables", "__tel.sass"

@import "../../views/components/Header/style"
@import "../../views/components/Request/style"
@import "../../views/components/Three/style"
@import "../../views/components/Logo/style"
@import "../../views/components/Map/style"
@import "../../views/components/MapList/style"
@import "../../views/components/Preload/style"

html, body
  height: 100%
  font-family: var(--main-font)
html
  font-size: 62.5%
body
  display: flex
  flex-direction: column
  background: linear-gradient(107.26deg, #F0F2F7 7.14%, #FFFEFB 92.66%)

//---- Контейнер
.inner
  max-width: 1540px
  margin-left: auto
  margin-right: auto
  @media (min-width: $large-min)
    padding-left: 72px
    padding-right: 72px
  @media (max-width: $large-max)
    padding-left: 15px
    padding-right: 15px
