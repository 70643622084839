//---- Resolution
$extra-large-min: 1540px
$extra-large-max: 1539px

$large-min: 1200px
$large-max: 1199px

$middle-min: 1000px
$middle-max: 999px

$medium-min: 769px
$medium-max: 768px

$small-min: 576px
$small-max: 575px

$extra-small-min: 375px
$extra-small-max: 374px

:root
  //---- Color
  --color-primary: #CF5A48
  --color-second: #00359C
  --color-white: #FFFFFF
  --color-white-550: rgba(255, 255, 255, .55)
  --color-gray: #F5F6F8
  --color-black: #1B1F28

  //---- Font
  --main-font: "Montserrat", sans-serif
