.three
  min-height: 100vh
  background-image: url('../images/bg-three.jpg')
  background-repeat: no-repeat
  background-size: cover
  @media (min-width: $medium-min)
    padding: 108px 102px
  @media (max-width: $medium-max) and (min-width: $small-min)
    padding: 50px
  @media (max-width: $small-max) and (min-width: $extra-small-min)
    padding: 30px 20px
  @media (max-width: $extra-small-max)
    padding: 30px 10px

  &__inner
    display: flex
    width: 100%
    height: 100%
    border-radius: 30px
    box-shadow: 4px 4px 27px #E8E8E8
    background-color: rgba(255, 255, 255, 0.8)
    backdrop-filter: blur(25px)
    @media (min-width: $large-min)
      justify-content: space-between
      padding: 60px
    @media (max-width: $large-max)
      flex-direction: column
      align-items: center
      padding: 40px 30px

  &__success
    position: fixed
    top: -100px
    left: 0
    width: 100%
    padding: 20px
    background-color: #28a745
    color: white
    font: 400 1.6rem var(--main-font)
    transition: all .3s ease-in-out

.form-three
  $parent: &
  margin-top: 35px
  @media (min-width: $medium-min)
    width: 472px
  @media (max-width: $medium-max)
    width: 100%
  &__title
    margin-bottom: 25px
    text-align: center
    font: 500 2.7rem/3.3rem var(--main-font)
    color: #363636

  &__box
    position: relative
    margin-bottom: 16px
  &__label
    &:not(&--inside)
      display: block
      margin-bottom: 8px
      font: 500 1.8rem var(--main-font)
      color: #363636
    &--inside
      position: absolute
      left: 14px
      top: 16px
      font: 500 2rem/2.4rem var(--main-font)
      color: rgba(54, 54, 54, .4)
      pointer-events: none
      transition: all .2s

  &__input
    width: 100%
    border: 1px solid rgba(0, 53, 156, 0.3)
    border-radius: 7px
    font: 500 1.6rem/2rem var(--main-font)
    color: #363636
    &::placeholder
      color: #8F8F8F
    &:focus
      border-color: var(--color-second)
      box-shadow: 0 0 0 1px var(--color-second)
    &:not(&--inside)
      margin-bottom: 16px
      padding: 19px 14px
    &--inside
      padding: 28px 14px 8px
      &:focus, &:not([value=""])
        ~ #{$parent}__label--inside
          top: 4px
          font: 500 1.4rem/1.7rem var(--main-font)
    &.invalid
      border-color: tomato
      ~ #{$parent}__label--inside
        color: tomato

  &__group
    &--full
      flex-direction: column
    &--grid
      display: grid
    &:not(&--grid)
      display: flex

  &__select
    position: relative
    display: inline-block
    margin-bottom: 20px
    border: 1px solid rgba(0, 53, 156, 0.3)
    border-radius: 7px
    &::after
      content: ""
      position: absolute
      top: 0
      bottom: 0
      right: 14px
      margin: auto 0
      width: 20px
      height: 20px
      pointer-events: none
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%2300359C' stroke-linecap='round' stroke-opacity='.5' stroke-width='2' d='m5 8 3.82 3.55c.65.6 1.7.6 2.36 0L15 8'/%3E%3C/svg%3E") no-repeat
    &--full
      width: 100%
    @media (min-width: $small-min)
      &--month
        min-width: 140px !important
      &:not(&--full)
        width: 100%
      &:not(:last-of-type)
        margin-right: 24px
    @media (max-width: $small-max)
      &--month
        min-width: 110px
      &:not(&--full)
        width: 100%
      &:not(:last-of-type)
        margin-right: 5px
      &::after
        display: none
    select
      width: 100%
      height: 100%
      padding: 18px 14px
      border-radius: 7px
      font: 400 1.6rem/2rem var(--main-font)
      cursor: pointer
      &:focus
        box-shadow: 0 0 0 2px var(--color-second)

  &__picture
    label
      display: block
      width: 100%
      margin-bottom: 20px
      padding: 18px
      border: 1px solid rgba(0, 53, 156, 0.3)
      border-radius: 10px
      text-align: center
      font: 500 2rem/2.4rem var(--main-font)
      color: rgba(54, 54, 54, .4)
      transition: all .2s
      cursor: pointer
      &:hover
        border-color: var(--color-second)
        box-shadow: 0 0 0 1px var(--color-second)
        color: #334a8b
    input
      &:focus
        ~ label
          border-color: var(--color-second)
          box-shadow: 0 0 0 1px var(--color-second)
          color: #334a8b

  &__button
    width: 100%
    padding: 14px
    background: var(--color-second)
    font: 500 1.9rem/150% var(--main-font)
    border-radius: 5px
    color: #FFFFFF
    transition: all .2s
    cursor: pointer
    &:hover
      background: #334a8b
    &:focus-visible
      background: #334a8b
