.logo
  display: block
  &--small
    width: 240px
    height: 76px

  &--big
    @media (min-width: $extra-small-min)
      width: 335px
      height: 107px
