#mapWrapper
  display: none
.map
  width: 100%
  height: 500px
  padding: 0
  margin: auto 0 25px 0
  &__title
    font: 600 2.5rem/100% var(--main-font)
    margin-bottom: 20px
  &__form
    max-width: 60%
    margin: 0 auto
    padding: 100px 0
  &__field
    margin-bottom: 25px
  &__label
    display: inline-block
    margin-bottom: 5px
    font: 500 1.5rem/2rem var(--main-font)
    color: #5a5a5a
  &__required
    color: red
  &__input
    width: 100%
    margin-bottom: 21px
    padding: 19px 14px
    border: 1px solid rgba(0,53,156,.3)
    border-radius: 7px
    font: 500 1.6rem/2rem var(--main-font)
    color: #363636
    &:focus
      border-color: #00359C
  &__select
    appearance: auto
    width: 100%
    height: 100%
    padding: 18px 14px
    border-radius: 7px
    font: 400 1.6rem/2rem var(--main-font)
    cursor: pointer
    border: 1px solid rgba(0,53,156,.3)
    border-radius: 7px
    &::before
      content: ""
      position: absolute
      top: 0
      bottom: 0
      right: 14px
      margin: auto 0
      width: 20px
      height: 20px
      pointer-events: none
      // background: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%2300359C' stroke-linecap='round' stroke-opacity='.5' stroke-width='2' d='m5 8 3.82 3.55c.65.6 1.7.6 2.36 0L15 8'/%3E%3C/svg%3E) no-repeat

  &__btn
    width: 100%
    padding: 14px
    background: var(--color-second)
    font: 500 1.9rem/150% var(--main-font)
    border-radius: 5px
    color: #fff
    transition: all .2s
    cursor: pointer
    &--delete
      background: var(--color-primary)

  &__success
    position: fixed
    top: -100px
    left: 0
    width: 100%
    padding: 20px
    background-color: #28a745
    color: var(--color-white)
    font: 400 1.6rem var(--main-font)
    transition: all .3s ease-in-out
    z-index: 1
