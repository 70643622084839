*, *:before, *:after
  box-sizing: border-box
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important
  -webkit-tap-highlight-color: transparent !important

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  vertical-align: baseline
  text-rendering: optimizeLegibility
  -webkit-font-smoothing: antialiased
  text-size-adjust: none

footer, header, nav, section, main
  display: block

body
  line-height: 1

ol, ul
  list-style: none

blockquote, q
  quotes: none

blockquote:before, blockquote:after, q:before, q:after
  content: ""
  content: none

table
  border-collapse: collapse
  border-spacing: 0

select
  background-color: transparent
  appearance: none
  border: none
  outline: none

//---- My Changes
.visually-hidden
  position: absolute
  width: 1px
  height: 1px
  margin: -1px
  border: 0
  padding: 0
  clip: rect(0 0 0 0)
  overflow: hidden

a, span
  text-decoration: none
  outline: none
  -webkit-tap-highlight-color: transparent

input, button, textarea
  background-color: transparent
  border: none
  border-radius: 0
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  -webkit-tap-highlight-color: transparent
  outline: none
div, input, button, textarea
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  -webkit-tap-highlight-color: transparent
  &:focus, &:focus-visible
    outline: none
  &:focus::placeholder
    color: transparent
input, textarea
  -webkit-appearance: none
  appearance: none
textarea
  resize: none
button
  padding: 0

input
  &::-ms-clear
    display: none
    width: 0
    height: 0
  &::-ms-reveal
    display: none
    width: 0
    height: 0
  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoratio
    display: none
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
    display: none
    -webkit-appearance: none
    margin: 0
  &[type="number"]
    -moz-appearance: textfield
  &:-webkit-autofill, &:-webkit-autofill:focus
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1)
