*, *:before, *:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-tap-highlight-color: transparent !important;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

select {
  background-color: transparent;
  appearance: none;
  border: none;
  outline: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

a, span {
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

input, button, textarea {
  background-color: transparent;
  border: none;
  border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

div, input, button, textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
div:focus, div:focus-visible, input:focus, input:focus-visible, button:focus, button:focus-visible, textarea:focus, textarea:focus-visible {
  outline: none;
}
div:focus::placeholder, input:focus::placeholder, button:focus::placeholder, textarea:focus::placeholder {
  color: transparent;
}

input, textarea {
  -webkit-appearance: none;
  appearance: none;
}

textarea {
  resize: none;
}

button {
  padding: 0;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoratio {
  display: none;
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input:-webkit-autofill, input:-webkit-autofill:focus {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px white;
}

:root {
  --color-primary: #CF5A48;
  --color-second: #00359C;
  --color-white: #FFFFFF;
  --color-white-550: rgba(255, 255, 255, .55);
  --color-gray: #F5F6F8;
  --color-black: #1B1F28;
  --main-font: "Montserrat", sans-serif;
}

.iti {
  position: relative;
  display: block;
  margin-bottom: 14px;
}
.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}
.iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: 20px;
}

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.iti__country {
  padding: 5px 10px;
  outline: none;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
  font-size: 1.3rem;
}

.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}
.iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.iti--separate-dial-code input {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}
.iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}
.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}
.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}
.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}
.iti__flag.iti__be {
  width: 18px;
}
.iti__flag.iti__ch {
  width: 15px;
}
.iti__flag.iti__mc {
  width: 19px;
}
.iti__flag.iti__ne {
  width: 18px;
}
.iti__flag.iti__np {
  width: 13px;
}
.iti__flag.iti__va {
  width: 15px;
}
.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}
.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}
.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}
.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}
.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}
.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}
.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}
.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}
.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}
.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}
.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}
.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}
.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}
.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}
.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}
.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}
.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}
.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}
.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}
.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}
.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}
.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}
.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}
.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}
.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}
.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}
.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}
.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}
.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}
.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}
.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}
.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}
.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}
.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}
.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}
.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}
.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}
.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}
.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}
.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}
.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}
.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}
.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}
.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}
.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}
.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}
.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}
.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}
.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}
.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}
.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}
.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}
.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}
.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}
.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}
.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}
.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}
.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}
.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}
.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}
.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}
.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}
.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}
.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}
.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}
.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}
.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}
.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}
.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}
.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}
.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}
.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}
.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}
.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}
.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}
.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}
.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}
.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}
.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}
.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}
.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}
.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}
.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}
.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}
.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}
.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}
.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}
.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}
.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}
.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}
.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}
.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}
.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}
.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}
.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}
.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}
.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}
.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}
.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}
.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}
.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}
.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}
.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}
.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}
.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}
.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}
.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}
.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}
.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}
.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}
.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}
.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}
.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}
.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}
.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}
.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}
.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}
.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}
.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}
.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}
.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}
.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}
.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}
.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}
.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}
.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}
.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}
.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}
.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}
.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}
.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}
.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}
.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}
.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}
.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}
.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}
.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}
.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}
.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}
.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}
.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}
.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}
.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}
.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}
.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}
.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}
.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}
.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}
.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}
.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}
.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}
.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}
.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}
.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}
.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}
.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}
.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}
.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}
.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}
.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}
.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}
.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}
.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}
.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}
.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}
.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}
.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}
.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}
.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}
.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}
.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}
.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}
.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}
.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}
.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}
.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}
.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}
.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}
.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}
.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}
.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}
.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}
.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}
.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}
.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}
.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}
.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}
.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}
.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}
.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}
.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}
.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}
.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}
.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}
.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}
.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}
.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}
.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}
.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}
.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}
.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}
.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}
.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}
.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}
.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}
.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}
.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}
.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}
.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}
.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}
.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}
.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}
.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}
.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}
.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}
.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}
.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}
.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}
.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}
.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}
.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}
.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}
.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}
.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}
.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}
.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}
.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}
.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}
.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}
.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}
.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}
.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}
.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}
.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}
.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}
.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}
.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}
.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}
.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}
.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}
.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}
.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}
.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}
.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}
.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}
.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}
.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}
.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}
.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}
.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}
.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}
.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}
.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}
.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}
.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}
.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}
.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}
.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}
.iti__flag.iti__np {
  background-color: transparent;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../images/flags@2x.png");
  }
}
.header {
  margin-top: 40px;
}

.request {
  position: relative;
  width: 100%;
  font: normal 300 1.2rem/1.3rem var(--main-font);
  color: #575F6E;
}
@media (min-width: 1000px) {
  .request {
    margin: 0 auto 20px;
  }
}
@media (max-width: 999px) {
  .request {
    margin: 60px auto;
  }
}
.request__title {
  font: normal 600 3.2rem/112% var(--main-font);
  text-align: center;
  color: #36487A;
}
.request__body {
  max-width: 491px;
  margin: 16px auto 0;
  font: normal 300 1.6rem/2.2rem var(--main-font);
  text-align: center;
  color: #575F6E;
}
.request__form {
  max-width: 491px;
  margin: 51px auto 0;
  background-color: #F8FAFD;
  border: 1px solid rgba(0, 53, 156, 0.3);
  box-shadow: 0px 4px 8px rgba(57, 57, 57, 0.09);
  border-radius: 9px;
}
@media (min-width: 376px) {
  .request__form {
    padding: 32px 27px;
  }
}
@media (max-width: 375px) {
  .request__form {
    padding: 20px 10px;
  }
}
.request__success {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #28a745;
  color: var(--color-white);
  font: 400 1.6rem var(--main-font);
  transition: all 0.3s ease-in-out;
}

.form__title {
  margin-bottom: 44px;
  font: 500 2.1rem/133% var(--main-font);
  color: #363636;
}
.form__input {
  width: 100%;
  margin-bottom: 21px;
  padding: 19px 14px;
  border: 1px solid rgba(0, 53, 156, 0.3);
  border-radius: 7px;
  font: 500 1.6rem/2rem var(--main-font);
  color: #363636;
}
.form__input::placeholder {
  color: #8F8F8F;
}
.form__input:focus {
  border-color: var(--color-second);
  box-shadow: 0 0 0 1px var(--color-second);
}
.form__input:filled {
  border-color: tomato;
}
.form__input--textarea {
  height: 200px;
}
.form__input.invalid {
  border-color: tomato;
}
.form__group {
  display: flex;
}
.form__select {
  position: relative;
  display: inline-block;
  margin-bottom: 22px;
  border: 1px solid rgba(0, 53, 156, 0.3);
  border-radius: 7px;
}
.form__select::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 14px;
  margin: auto 0;
  width: 20px;
  height: 20px;
  pointer-events: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%2300359C' stroke-linecap='round' stroke-opacity='.5' stroke-width='2' d='m5 8 3.82 3.55c.65.6 1.7.6 2.36 0L15 8'/%3E%3C/svg%3E") no-repeat;
}
@media (min-width: 576px) {
  .form__select {
    width: 108px;
  }
  .form__select--month {
    width: 140px;
  }
  .form__select:not(:last-of-type) {
    margin-right: 24px;
  }
}
@media (max-width: 575px) {
  .form__select {
    width: 100%;
  }
  .form__select--month {
    min-width: 110px;
  }
  .form__select:not(:last-of-type) {
    margin-right: 5px;
  }
  .form__select::after {
    display: none;
  }
}
.form__select select {
  width: 100%;
  height: 100%;
  padding: 18px 14px;
  border-radius: 7px;
  font: 400 1.6rem/2rem var(--main-font);
  cursor: pointer;
}
.form__select select:focus {
  box-shadow: 0 0 0 2px var(--color-second);
}
.form__button {
  width: 100%;
  padding: 14px;
  background: var(--color-second);
  font: 500 1.9rem/150% var(--main-font);
  border-radius: 5px;
  color: #FFFFFF;
  transition: all 0.2s;
  cursor: pointer;
}
.form__button:hover {
  background: #334a8b;
}
.form__button:focus-visible {
  background: #334a8b;
}

.three {
  min-height: 100vh;
  background-image: url("../images/bg-three.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 769px) {
  .three {
    padding: 108px 102px;
  }
}
@media (max-width: 768px) and (min-width: 576px) {
  .three {
    padding: 50px;
  }
}
@media (max-width: 575px) and (min-width: 375px) {
  .three {
    padding: 30px 20px;
  }
}
@media (max-width: 374px) {
  .three {
    padding: 30px 10px;
  }
}
.three__inner {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  box-shadow: 4px 4px 27px #E8E8E8;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(25px);
}
@media (min-width: 1200px) {
  .three__inner {
    justify-content: space-between;
    padding: 60px;
  }
}
@media (max-width: 1199px) {
  .three__inner {
    flex-direction: column;
    align-items: center;
    padding: 40px 30px;
  }
}
.three__success {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #28a745;
  color: white;
  font: 400 1.6rem var(--main-font);
  transition: all 0.3s ease-in-out;
}

.form-three {
  margin-top: 35px;
}
@media (min-width: 769px) {
  .form-three {
    width: 472px;
  }
}
@media (max-width: 768px) {
  .form-three {
    width: 100%;
  }
}
.form-three__title {
  margin-bottom: 25px;
  text-align: center;
  font: 500 2.7rem/3.3rem var(--main-font);
  color: #363636;
}
.form-three__box {
  position: relative;
  margin-bottom: 16px;
}
.form-three__label:not(.form-three__label--inside) {
  display: block;
  margin-bottom: 8px;
  font: 500 1.8rem var(--main-font);
  color: #363636;
}
.form-three__label--inside {
  position: absolute;
  left: 14px;
  top: 16px;
  font: 500 2rem/2.4rem var(--main-font);
  color: rgba(54, 54, 54, 0.4);
  pointer-events: none;
  transition: all 0.2s;
}
.form-three__input {
  width: 100%;
  border: 1px solid rgba(0, 53, 156, 0.3);
  border-radius: 7px;
  font: 500 1.6rem/2rem var(--main-font);
  color: #363636;
}
.form-three__input::placeholder {
  color: #8F8F8F;
}
.form-three__input:focus {
  border-color: var(--color-second);
  box-shadow: 0 0 0 1px var(--color-second);
}
.form-three__input:not(.form-three__input--inside) {
  margin-bottom: 16px;
  padding: 19px 14px;
}
.form-three__input--inside {
  padding: 28px 14px 8px;
}
.form-three__input--inside:focus ~ .form-three__label--inside, .form-three__input--inside:not([value=""]) ~ .form-three__label--inside {
  top: 4px;
  font: 500 1.4rem/1.7rem var(--main-font);
}
.form-three__input.invalid {
  border-color: tomato;
}
.form-three__input.invalid ~ .form-three__label--inside {
  color: tomato;
}
.form-three__group--full {
  flex-direction: column;
}
.form-three__group--grid {
  display: grid;
}
.form-three__group:not(.form-three__group--grid) {
  display: flex;
}
.form-three__select {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 53, 156, 0.3);
  border-radius: 7px;
}
.form-three__select::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 14px;
  margin: auto 0;
  width: 20px;
  height: 20px;
  pointer-events: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%2300359C' stroke-linecap='round' stroke-opacity='.5' stroke-width='2' d='m5 8 3.82 3.55c.65.6 1.7.6 2.36 0L15 8'/%3E%3C/svg%3E") no-repeat;
}
.form-three__select--full {
  width: 100%;
}
@media (min-width: 576px) {
  .form-three__select--month {
    min-width: 140px !important;
  }
  .form-three__select:not(.form-three__select--full) {
    width: 100%;
  }
  .form-three__select:not(:last-of-type) {
    margin-right: 24px;
  }
}
@media (max-width: 575px) {
  .form-three__select--month {
    min-width: 110px;
  }
  .form-three__select:not(.form-three__select--full) {
    width: 100%;
  }
  .form-three__select:not(:last-of-type) {
    margin-right: 5px;
  }
  .form-three__select::after {
    display: none;
  }
}
.form-three__select select {
  width: 100%;
  height: 100%;
  padding: 18px 14px;
  border-radius: 7px;
  font: 400 1.6rem/2rem var(--main-font);
  cursor: pointer;
}
.form-three__select select:focus {
  box-shadow: 0 0 0 2px var(--color-second);
}
.form-three__picture label {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 18px;
  border: 1px solid rgba(0, 53, 156, 0.3);
  border-radius: 10px;
  text-align: center;
  font: 500 2rem/2.4rem var(--main-font);
  color: rgba(54, 54, 54, 0.4);
  transition: all 0.2s;
  cursor: pointer;
}
.form-three__picture label:hover {
  border-color: var(--color-second);
  box-shadow: 0 0 0 1px var(--color-second);
  color: #334a8b;
}
.form-three__picture input:focus ~ label {
  border-color: var(--color-second);
  box-shadow: 0 0 0 1px var(--color-second);
  color: #334a8b;
}
.form-three__button {
  width: 100%;
  padding: 14px;
  background: var(--color-second);
  font: 500 1.9rem/150% var(--main-font);
  border-radius: 5px;
  color: #FFFFFF;
  transition: all 0.2s;
  cursor: pointer;
}
.form-three__button:hover {
  background: #334a8b;
}
.form-three__button:focus-visible {
  background: #334a8b;
}

.logo {
  display: block;
}
.logo--small {
  width: 240px;
  height: 76px;
}
@media (min-width: 375px) {
  .logo--big {
    width: 335px;
    height: 107px;
  }
}

#mapWrapper {
  display: none;
}

.map {
  width: 100%;
  height: 500px;
  padding: 0;
  margin: auto 0 25px 0;
}
.map__title {
  font: 600 2.5rem/100% var(--main-font);
  margin-bottom: 20px;
}
.map__form {
  max-width: 60%;
  margin: 0 auto;
  padding: 100px 0;
}
.map__field {
  margin-bottom: 25px;
}
.map__label {
  display: inline-block;
  margin-bottom: 5px;
  font: 500 1.5rem/2rem var(--main-font);
  color: #5a5a5a;
}
.map__required {
  color: red;
}
.map__input {
  width: 100%;
  margin-bottom: 21px;
  padding: 19px 14px;
  border: 1px solid rgba(0, 53, 156, 0.3);
  border-radius: 7px;
  font: 500 1.6rem/2rem var(--main-font);
  color: #363636;
}
.map__input:focus {
  border-color: #00359C;
}
.map__select {
  appearance: auto;
  width: 100%;
  height: 100%;
  padding: 18px 14px;
  border-radius: 7px;
  font: 400 1.6rem/2rem var(--main-font);
  cursor: pointer;
  border: 1px solid rgba(0, 53, 156, 0.3);
  border-radius: 7px;
}
.map__select::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 14px;
  margin: auto 0;
  width: 20px;
  height: 20px;
  pointer-events: none;
}
.map__btn {
  width: 100%;
  padding: 14px;
  background: var(--color-second);
  font: 500 1.9rem/150% var(--main-font);
  border-radius: 5px;
  color: #fff;
  transition: all 0.2s;
  cursor: pointer;
}
.map__btn--delete {
  background: var(--color-primary);
}
.map__success {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #28a745;
  color: var(--color-white);
  font: 400 1.6rem var(--main-font);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.map-list {
  width: 100%;
  height: 500px;
  padding: 0;
  margin: 75px auto;
}

.preload {
  position: relative;
  height: 100vh;
  background-image: url("../images/bg-preload.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 1000px) {
  .preload {
    padding: 180px 0;
    background-position: right;
  }
}
@media (max-width: 999px) {
  .preload {
    padding: 120px 0;
    background-position: 75%;
  }
}
.preload__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.preload__contacts {
  margin-top: 30px;
}
.preload__item {
  color: #363636;
}
@media (max-width: 575px) {
  .preload__item:not(:last-of-type) {
    margin-bottom: 15px;
  }
  .preload__item span {
    display: block;
    text-align: center;
  }
}
.preload__item span {
  font: 500 2.4rem/125% var(--main-font);
}
.preload__link, .preload__address {
  margin-left: 5px;
  font: 400 2.4rem/125% var(--main-font);
  color: inherit;
}
@media (min-width: 576px) {
  .preload__link, .preload__address {
    display: inline-block;
  }
}
@media (max-width: 575px) {
  .preload__link, .preload__address {
    display: block;
    text-align: center;
  }
}
.preload__link {
  transition: all 0.2s;
}
.preload__link:hover {
  color: var(--color-black);
  text-decoration: underline;
}

html, body {
  height: 100%;
  font-family: var(--main-font);
}

html {
  font-size: 62.5%;
}

body {
  display: flex;
  flex-direction: column;
  background: linear-gradient(107.26deg, #F0F2F7 7.14%, #FFFEFB 92.66%);
}

.inner {
  max-width: 1540px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1200px) {
  .inner {
    padding-left: 72px;
    padding-right: 72px;
  }
}
@media (max-width: 1199px) {
  .inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}